import React from 'react'
import Layout from '../components/layout'

export default function muchAdoNews1() {
    return (
        <Layout>
            <div style={{padding: "108px 20px 20px 10px", width: 400, margin: "0 auto"}}>
                <h1>Scandalous Crier</h1>

                <h2>Beatrice and Benedick Spotted on Romantic Dinner Together</h2>

                <p>These past couple weeks have been a proverbial roller coaster of emotions. Surely the ups and downs of scandal and loss have hit all of Messina hard, but none more so than Beatrice, the closest friend and cousin of our late, dear Hero. 

Now, it looks like she may have found someone to help dry her tears.

After articles and articles from yours truly speculating about the unprofessed attraction between Beatrice and Benedick, the two were finally spotted dining together in public. The usually sparring pair even seemed to enjoy each another’s company.

“They bantered a little, but in a flirtatious way,” said one eavesdropped who happened to be seated at a nearby table.

Perhaps Hero’s tragedy has forced these once outward foes to face their true feelings for one another.
</p>
            </div>
        </Layout>
    )
}